import $ from "jquery";
import Uppy, { XHRUpload, DragDrop } from 'uppy';
import Parallax from 'parallax-js'
import { TweenMax, TimelineLite } from "gsap";
import Scrollbar from 'smooth-scrollbar';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import Datepicker from '@chenfengyuan/datepicker/dist/datepicker';


class App {

  run() {
    this.burgermenu();
    this.scrollDown();
    this.revealelement();
    this.detectsystem();
    this.countdown();
    this.parallax();
    this.uploadFile();
    this.apply();
  }

  scrollDown() {
    /**
     * Scroll Anchor Animation
     */

    var headersHeight = $('header').height();

    $('a[href^="#"].roll').on('click',function (e) {
      e.preventDefault();
      var target = this.hash,
          $target = $(target);
      $('html, body').stop().animate({
        'scrollTop': $target.offset().top - headersHeight
      }, 800);
    });
  }


  uploadFile() {

    if ($('.pick-files').length > 0 === true ) {

      const Uppy = require('@uppy/core')
      const Dashboard = require('@uppy/dashboard')
      const GoogleDrive = require('@uppy/google-drive')
      const Dropbox = require('@uppy/dropbox')
      // const Instagram = require('@uppy/instagram')
      // const Facebook = require('@uppy/facebook')
      // const OneDrive = require('@uppy/onedrive')
      // const Webcam = require('@uppy/webcam')
      const Tus = require('@uppy/tus')

      const uppy = Uppy({
        debug: true,
        autoProceed: false,
        restrictions: {
          maxFileSize: 100000000000000000000000000,
          minNumberOfFiles: 1
        }
      })
          .use(Dashboard, {
            trigger: '.UppyModalOpenerBtn',
            inline: true,
            target: '.pick-files',
            replaceTargetContent: true,
            showProgressDetails: true,
            note: '',
            height: 470,
            width: '100%',
            metaFields: [
              { id: 'name', name: 'Name', placeholder: 'file name' },
              { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
            ],
            locale: [{
              strings: {
                dropPasteImport: 'Ciao files here, paste, %{browse} or import from',
              },
            }],
            browserBackButtonClose: true
          })
          .use(GoogleDrive, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
          .use(Dropbox, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
          // .use(Instagram, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
          // .use(Facebook, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
          // .use(OneDrive, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
          // .use(Webcam, { target: Dashboard })
          .use(Tus, { endpoint: 'https://master.tus.io/files/' })

      uppy.on('complete', result => {
        console.log('successful files:', result.successful)
        console.log('failed files:', result.failed)
      })
    }
  }

  detectsystem() {

    function getOS() {
      var userAgent = window.navigator.userAgent,
          platform = window.navigator.platform,
          macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'],
          os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'macOS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'windows';
      } else if (/Android/.test(userAgent)) {
        os = 'android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'linux';
      }

      return os;
    }

    $('body').addClass(getOS);


    //alert(getOS());
  }

  burgermenu() {
    var stato = false
    $('#drop').click(function () {

      if (stato == false) {

        $('#drop p').eq(0).addClass('ruotaP');
        $('#drop p').eq(1).hide();
        $('#drop p').eq(2).addClass('ruotaN');
        $('#drop').addClass('rotate-180');
        $('.nav-mobile').addClass('spostaNav');
        $('.menu-header ul').fadeOut();
        $('#drop p').addClass('color-p');

        stato = true

      } else {
        $('#drop p').eq(0).removeClass('ruotaP');
        $('#drop p').eq(1).show();
        $('#drop p').eq(2).removeClass('ruotaN');
        $('#drop').removeClass('rotate-180');
        $('.nav-mobile').removeClass('spostaNav');
        $('.menu-header ul').fadeIn();
        $('#drop p').removeClass('color-p');

        stato = false

      }
    });


    // CARD INTERESSI

    $('.card-interests-select').on("click", function (){
      $(this).toggleClass('opacity-1');
      $(this).parent().find(".card-interests__title h3").toggleClass("text-green");
    });

    // DATE PIKER

    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: 'dd-mm-yyyy',
      date: new Date(1980, 1, 7),
      startDate: '01/7/1950'
    });


    // OPEN SELECT PRODUCT

    $('.close-select').on("click", function (){
      $('#select-product-protect').toggleClass('open-select');
    });

    $('.cat-products').on("click", function (){
      $('#select-product-protect').toggleClass('open-select');
    });

    $('.bc-step-3').fadeOut();
    
    $('.other-cat').on("click", function (){
      $('.bc-step-3').fadeOut();
    });

    $('#nav-industrial-tab').on("click", function (){
      $('.bc-step-3').fadeIn();
    });





    

    // APPEND INPUT

    $('.content-100-text').on("click", function (){
      $( ".upload-project__edit--content" ).append( "<div class='col-12 delete-content'><svg class='delete-icon' viewBox='0 0 51 51'><use xlink:href='/svg/sprite.svg#plus'></use></svg><textarea class='form-control' placeholder='Aggiungi una breve'></textarea></div>" );
    });

    $('.content-50-text').on("click", function (){
      $( ".upload-project__edit--content" ).append( "<div class='col-6 delete-content'><svg class='delete-icon' viewBox='0 0 51 51'><use xlink:href='/svg/sprite.svg#plus'></use></svg><textarea class='form-control' placeholder='Aggiungi una breve'></textarea></div>" );
    });

    // SELECT CATEGORY IN INFO PROJECT

    $('.card__infoProject--category ul li').on("click", function (){
      $(this).toggleClass('category-select');
    });

    // SELECT PRODUCT IN BLOCKCHAIN UPLOAD

    $('.single-product').on("click", function (){
      $(this).toggleClass('text-green font-bold');
    });

    // CARD PRICE

    $('.card__plan--price').on("click", function (){
      if ($(this).hasClass("space-active") === false ) {
        $('.card__plan--price').removeClass('space-active')
        $(this).addClass('space-active');
        $('.show-cart').removeClass('show-cart');
      }
    });

    // Dropdown Hover

    $('.dropright').hover(function() {
      $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
    }, function() {
      $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
    });

    $('.svg-like').on("click", function(){
      $(this).toggleClass('fill-green');
      $(this).parent().find(".comments__like-n").toggleClass('d-block');
    });

    $('.more-comments').on("click", function(){
      $('.all-comments').toggleClass('h-auto');
    });


    $(window).on("resize load", function(){

      if ($(window).width() <= 575) {

        $('.sticky-info__button, .sticky-info__img').removeClass('dropright');
        $('.sticky-info__button, .sticky-info__img').addClass('dropup');

      }else{
        $('.sticky-info__button, .sticky-info__img').addClass('dropright');
        $('.sticky-info__button, .sticky-info__img').removeClass('dropup');
      }
    });


    //hover card explore

    $('.no-select').hover(function(){
      $('.explore-select .overlay').css({left: 0});
      $('.explore-select img').addClass('img-filter');
      $('.explore-select .explore-title').css({bottom: "-101%"});
    }, function(){
      $('.explore-select .overlay').css({left: "-105%"});
      $('.explore-select img').removeClass('img-filter');
      $('.explore-select .explore-title').css({bottom: '-1px'});
    });

  }

  countdown() {
    function makeTimer() {

      var endTime = new Date("29 May 2022 9:56:00 GMT+01:00");
      endTime = (Date.parse(endTime) / 1000);

      var now = new Date();
      now = (Date.parse(now) / 1000);

      var timeLeft = endTime - now;

      var days = Math.floor(timeLeft / 86400);
      var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
      //var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
      //var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));

      if (hours < "10") { hours = "0" + hours; }
      //if (minutes < "10") { minutes = "0" + minutes; }
      //if (seconds < "10") { seconds = "0" + seconds; }

      $(".days").html(days + "<span>Days</span>");
      $(".hours").html(hours + "<span>Hours</span>");
    }

    setInterval(function() { makeTimer(); }, 1000);
  }

  revealelement() {
    // reveal items while scrolling the page

    var winWidth = $(window).width();
    var winHeight = $(window).height();
    var tollerance = winHeight / 5;

    if(winWidth < 768 ) {
      tollerance = 0;
    }

    function reveal () {

      var count = 1;

      $('.reveal.not-revealed').each(function () {
        var $this = $(this);

        if($(document).scrollTop() + winHeight - tollerance > $this.offset().top && !$this.attr('revealed')) {

          setTimeout(function () {
            $this.removeClass('not-revealed');
          }, count * 200);

          count++;
        }
      });
    }

    setTimeout(function () {
      reveal();
    }, 150);

    $(document).scroll(function () {
      reveal();
    });

    $(window).resize(function () {
      reveal();
    });
  }


  parallax() {

    if ($('#scene').length > 0 === true) {

      const sceneParallax = document.getElementById('scene');
      const sectionWelcomeImgInstance = new Parallax(sceneParallax, {
        relativeInput: true,
        selector: '.star',
        frictio: 3,
        hoverOnly: true,
      });
    }

    if ($('#scene2').length > 0 === true) {

      const sceneParallax = document.getElementById('scene2');
      const sectionStar = new Parallax(sceneParallax, {
        relativeInput: true,
        selector: '.star-2',
        frictio: 3,
        hoverOnly: true,
      });
    }

    if ($('#scene3').length > 0 === true) {

      const sceneParallax = document.getElementById('scene3');
      const sectionStar = new Parallax(sceneParallax, {
        relativeInput: true,
        selector: '.star-2',
        frictio: 3,
      });
    }

    if ($('#scene4').length > 0 === true) {

      const sceneParallax = document.getElementById('scene4');
      const sectionStar = new Parallax(sceneParallax, {
        relativeInput: true,
        selector: '.star-2',
        frictio: 2,
      });
    }
  }


  apply() {

    if ($('.smooth-scroll').length > 0 === true) {
      // Custom smooth-scrollbar
      var scrollBarScene = Scrollbar.init(document.querySelector('.smooth-scroll'), {
        refreshInterval: 0,
        renderByPixels: true,
        continuousScrolling: false,
        syncCallbacks: true,
      });



      // Array for accoding various scrollmagic scenes
      let scenes = [];

      // init controller ScrollMagic
      const controller = new ScrollMagic.Controller();
      // const debug = new ScrollMagic.addIndicators(); // add indicators (requires plugin)();


      // scena 1

      const text = TweenMax.to('.right', 1, { x: "65%", ease: Power2.easeOut });

      const moveText = new ScrollMagic.Scene({
        triggerElement: ".right",
        duration: "200%",
        triggerHook: 1,
      }).setTween(text);



      // scena 2
      const imagedown = TweenMax.to('.down', 1, { y: "130px", ease: Power2.easeOut });

      const moveCard = new ScrollMagic.Scene({
        triggerElement: ".down",
        duration: "200%",
        triggerHook: 1,
      }).setTween(imagedown);

      // scena 3
      const textleft = TweenMax.to('.left', 1, { x: "-65%", ease: Power2.easeOut });

      const moveTextleft = new ScrollMagic.Scene({
        triggerElement: ".left",
        duration: "200%",
        triggerHook: 1,
      }).setTween(textleft);

      // scena 4
      const rotateDiv = TweenMax.to('.background-animation', 1, { skewY:7, ease: Power2.easeOut });

      const rotateBackground = new ScrollMagic.Scene({
        triggerElement: ".background-animation",
        duration: "150%",
        triggerHook: 1,
      }).setTween(rotateDiv)

      // scena 4.1
      const rotateDivNeg = TweenMax.to('.background-animation-neg', 1, { skewY:-7, ease: Power2.easeOut });

      const rotateBackgroundNeg = new ScrollMagic.Scene({
        triggerElement: ".background-animation",
        duration: "150%",
        triggerHook: 1,
      }).setTween(rotateDivNeg)

      // scena 5
      const fadeIn = TweenMax.to('.fade-in', 1, { opacity:1, ease: Power2.easeOut });

      const fadeElement = new ScrollMagic.Scene({
        triggerElement: ".fade-in",
        duration: "300%",
        triggerHook: 1,
      }).setTween(fadeIn);

      // Parallax Grid
      const moveGrid = TweenMax.to('.about-bg', 1, {'background-position': "50% -100px", ease: 'power2.out'});

      const gridLine = new ScrollMagic.Scene({
        triggerElement: ".about-bg",
        duration: "200%",
        triggerHook: 1,
      }).setTween(moveGrid);

      // scena 6
      const pdown = TweenMax.to('.down-2', 1, { y: "400px", ease: Power2.easeOut });

      const moveP = new ScrollMagic.Scene({
        triggerElement: ".down-2",
        duration: "200%",
        triggerHook: 1,
      }).setTween(pdown);

      // scena 7
      const boxdown = TweenMax.to('.down-box', 1, { y: "150px", ease: Power2.easeOut });

      const moveBox = new ScrollMagic.Scene({
        triggerElement: ".down-box",
        duration: "150%",
        triggerHook: 0.8,
      }).setTween(boxdown);

      // Array of animation to call .addIndicators(false)

      scenes.push(moveText, moveTextleft, moveCard, rotateBackground, fadeElement, gridLine, moveP, moveBox, rotateBackgroundNeg);

      scenes.forEach(function(el) {
        controller.addScene(el);

        scrollBarScene.addListener(function() {
          el.refresh();
        });
      });



      $(window).on("resize load", function(){

        if ($(window).width() <= 991) {

          moveCard.enabled(false);
        } else {
          moveCard.enabled(true);
        };

        if ($(window).width() <= 575) {

          $('.sticky-info__button, .sticky-info__img').removeClass('dropright');
          $('.sticky-info__button, .sticky-info__img').addClass('dropup');

        };

      });
      //
      // if ($('.down').length > 0 === true) {
      //   moveCard.enabled(false);
      // }
      //
      // if ($('.right').length > 0 === true) {
      //   moveText.enabled(false);
      // }

    }
  }
}

const app = new App();
export default app;
